/**
 * DETAIL THUMBNAIL
 */

.DetailThumbnail {
  display: block;
  position: relative;

  &,
  &-overlay {
    border-radius: 5px;
  }

  &.is-editable {
    cursor: pointer;
  }

  &-overlay {
    opacity: 0;
    transition: opacity 0.14s ease;
    color: white;
    background: rgba(black, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &.is-editable:hover &-overlay {
    opacity: 1;
  }
}
