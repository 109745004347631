/**
 * SEARCHBAR
 */

.SearchBar {
  padding: 0.25em 1em;
  display: flex;
  align-items: center;
  width: 400px;

  &-icon {
    opacity: 0.55;
  }

  &-divider {
    height: 28px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.12);
    margin: 0.35em 0.75em;
  }
}
